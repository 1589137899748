import React, { useState, useEffect } from "react";
import axios from "axios";

import Lead from "../../components/Lead/Lead";

const LeadsNoWebsite = () => {
   const [leads, setLeads] = useState([]);
   useEffect(() => {
      axios.get("/api/lead/getAll").then((res) => {
         setLeads(res.data);
      });
   }, []);
   return (
      <div className="LeadsNoWebsite">
         {leads
            .filter(
               (lead) =>
                  lead.website.includes("wordpress") ||
                  lead.website.includes("wixsite") ||
                  lead.website.includes("facebook") ||
                  lead.website.includes("canva") ||
                  lead.website.includes("business.site")
            )
            .map((lead) => (
               <Lead lead={lead} />
            ))}
      </div>
   );
};

export default LeadsNoWebsite;
