import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../Context/AuthContext.jsx";
import axios from "axios";

import "./AdminHeader.css";

const AdminHeader = ({ children }) => {
   const [closedMenu, setClosedMenu] = useState(false);
   const [desktopView, setDesktopView] = useState(null);

   const { authUser, setAuthUser } = useAuthContext();
   const navigate = useNavigate();

   useEffect(() => {
      if (window.innerWidth <= 1200) {
         setDesktopView(false);
      } else {
         setDesktopView(true);
      }
   }, []);

   const handleLogout = async () => {
      console.log("yeah.");
      const res = await axios.get("/api/auth/logout");
      localStorage.removeItem("MMZ-Media-User");
      setAuthUser(null);
      navigate("/");
   };

   const menuStyle = closedMenu
      ? {
           animation: "closeMenuItems .5s ease-in-out forwards",
        }
      : {
           animation: "openMenuItems .5s ease-in-out forwards",
        };

   return (
      <div className="biggerCont">
         {desktopView ? (
            <div className="container">
               <div
                  className="AdminHeader"
                  style={
                     closedMenu
                        ? {
                             animation:
                                "closeMenuAdminHeader .5s ease-in-out forwards",
                          }
                        : {
                             animation:
                                "openMenuAdminHeader .5s ease-in-out forwards",
                          }
                  }
               >
                  {closedMenu ? (
                     <svg
                        className="sideMenu"
                        id="Layer_2"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 497.09 374.11"
                        onClick={() => setClosedMenu(!closedMenu)}
                        style={
                           closedMenu
                              ? {
                                   animation:
                                      "closeMenuSideMenu .5s ease-in-out forwards",
                                }
                              : {
                                   animation:
                                      "openMenuSideMenu .5s ease-in-out forwards",
                                }
                        }
                     >
                        <g id="Layer_1-2" data-name="Layer 1">
                           <g
                              id="Menu_Menu_Alt_03"
                              data-name="Menu / Menu_Alt_03"
                           >
                              <path
                                 id="Vector"
                                 className="cls-1"
                                 d="m33.33,340.78h245.96M33.33,187.06h430.43M33.33,33.33h245.96"
                              />
                           </g>
                        </g>
                     </svg>
                  ) : (
                     <svg
                        className="close"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 57.69 57.69"
                        onClick={() => setClosedMenu(!closedMenu)}
                     >
                        <g id="Layer_1-2" data-name="Layer 1">
                           <line
                              className="cls-1"
                              x1="5"
                              y1="5"
                              x2="52.69"
                              y2="52.69"
                           />
                           <line
                              className="cls-1"
                              x1="52.69"
                              y1="5"
                              x2="5"
                              y2="52.69"
                           />
                        </g>
                     </svg>
                  )}
                  {!closedMenu ? (
                     <svg
                        id="Layer_2"
                        className="logo"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 473.87 193.53"
                     >
                        <g id="Layer_2-2" data-name="Layer 2">
                           <g id="Logo">
                              <g>
                                 <path
                                    className="cls-3"
                                    d="m26.84,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                                 />
                                 <path
                                    className="cls-3"
                                    d="m93.14,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                                 />
                                 <path
                                    className="cls-3"
                                    d="m189.71,106.54l1.48-7.03h21.45l-1.44,7.03h-21.49Z"
                                 />
                                 <path
                                    className="cls-3"
                                    d="m216.02,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                                 />
                                 <path
                                    className="cls-3"
                                    d="m311.78,112l6.84.7c-.98,3.39-3.25,6.62-6.78,9.68-3.54,3.06-7.75,4.59-12.65,4.59-3.06,0-5.86-.71-8.41-2.12-2.55-1.41-4.5-3.47-5.83-6.16-1.33-2.69-2-5.76-2-9.21,0-4.51,1.04-8.88,3.13-13.12,2.09-4.24,4.79-7.38,8.1-9.44,3.32-2.06,6.9-3.09,10.77-3.09,4.92,0,8.85,1.53,11.8,4.59,2.94,3.06,4.41,7.23,4.41,12.51,0,2.02-.18,4.09-.54,6.22h-30.39c-.1.8-.16,1.53-.16,2.18,0,3.86.89,6.81,2.66,8.84,1.77,2.03,3.94,3.05,6.51,3.05,2.41,0,4.78-.79,7.11-2.37,2.33-1.58,4.15-3.86,5.44-6.84Zm-20.44-10.22h23.16c.03-.73.04-1.24.04-1.55,0-3.52-.88-6.22-2.64-8.1-1.76-1.88-4.03-2.82-6.8-2.82-3.01,0-5.75,1.04-8.22,3.11-2.47,2.07-4.32,5.19-5.54,9.37Z"
                                 />
                                 <path
                                    className="cls-3"
                                    d="m353.55,120.05c-4.02,4.61-8.21,6.92-12.59,6.92-3.91,0-7.17-1.44-9.77-4.33-2.6-2.89-3.91-7.08-3.91-12.57,0-5.03,1.03-9.62,3.09-13.78,2.06-4.16,4.64-7.27,7.73-9.35,3.1-2.07,6.2-3.11,9.31-3.11,5.13,0,9,2.47,11.62,7.42l4.66-22.19h7l-11.89,56.97h-6.49l1.24-5.98Zm-19.28-11.31c0,2.88.28,5.14.85,6.8.57,1.66,1.54,3.04,2.91,4.14,1.37,1.1,3.02,1.65,4.94,1.65,3.19,0,6.08-1.66,8.67-4.97,3.47-4.4,5.21-9.85,5.21-16.32,0-3.26-.85-5.82-2.56-7.66-1.71-1.84-3.86-2.76-6.45-2.76-1.68,0-3.22.38-4.61,1.13-1.39.75-2.76,2.03-4.12,3.83-1.36,1.8-2.51,4.09-3.44,6.86-.93,2.77-1.4,5.21-1.4,7.31Z"
                                 />
                                 <path
                                    className="cls-3"
                                    d="m369.72,126.03l8.63-41.27h7.03l-8.63,41.27h-7.03Zm10.26-49.01l1.67-7.97h7l-1.67,7.97h-7Z"
                                 />
                                 <path
                                    className="cls-3"
                                    d="m415.5,120.9c-2.44,2.1-4.78,3.63-7.03,4.61-2.25.97-4.66,1.46-7.23,1.46-3.81,0-6.88-1.12-9.21-3.36-2.33-2.24-3.5-5.11-3.5-8.61,0-2.31.52-4.35,1.57-6.12,1.05-1.77,2.44-3.2,4.18-4.27,1.74-1.07,3.86-1.85,6.37-2.31,1.58-.31,4.58-.56,9-.74,4.42-.18,7.58-.65,9.5-1.4.54-1.92.82-3.51.82-4.78,0-1.63-.6-2.91-1.79-3.85-1.63-1.3-4.02-1.94-7.15-1.94-2.95,0-5.37.65-7.25,1.96-1.88,1.31-3.25,3.17-4.1,5.58l-7.11-.62c1.45-4.09,3.75-7.23,6.9-9.4s7.12-3.26,11.91-3.26c5.1,0,9.15,1.22,12.13,3.65,2.28,1.81,3.42,4.17,3.42,7.07,0,2.2-.32,4.75-.97,7.66l-2.29,10.26c-.73,3.26-1.09,5.92-1.09,7.97,0,1.3.28,3.16.85,5.6h-7.11c-.39-1.35-.66-3.06-.82-5.13Zm2.6-15.78c-.98.39-2.04.69-3.17.89-1.13.21-3.01.43-5.66.66-4.09.36-6.98.82-8.67,1.38-1.68.56-2.95,1.44-3.81,2.66-.85,1.22-1.28,2.56-1.28,4.04,0,1.97.68,3.59,2.04,4.86,1.36,1.27,3.3,1.9,5.81,1.9,2.33,0,4.57-.61,6.72-1.85,2.15-1.23,3.85-2.95,5.09-5.15,1.24-2.2,2.22-5.34,2.91-9.4Z"
                                 />
                              </g>
                              <path
                                 className="cls-2"
                                 d="m124.71,151.77c12.46-11.76,31.16-14.53,45.17-17.09,6.36-11.9,8.38-26.43-4.65-20.68-2.21.96-3.3-2.56-2.17-3.54,6.04-5.42,15.77-13.18,19.4-20.95,7.73-16.41-14.96-8.1-25.06,15.38-.89,2.13-5.05,1.17-3.89-1.16,14.57-30.13,41.46-31.36,35.37-14.39-2.68,7.31-11.66,15.72-16.97,20.65,9.01,1.74,9.94,11.11,5.7,22.9,11.65-3.23,27.78-15.04,32.68-24.21.71-1.44,3.17-.41,2.21,1.09-9.2,14.37-22.14,22.28-36.72,27.46-7.36,14.02-20.18,30.08-35.32,37.49-18.84,9.29-33.54-6.32-15.75-22.96Zm15.74,16.06c8.16-4.84,19.05-13.46,28.39-29.89-62.73,9.98-52.36,44.35-28.39,29.89Z"
                              />
                           </g>
                        </g>
                     </svg>
                  ) : null}
                  <svg
                     className="profile"
                     id="Layer_2"
                     data-name="Layer 2"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 250 252.55"
                     style={
                        closedMenu
                           ? {
                                animation:
                                   "closeMenuProfile .5s ease-in-out forwards",
                             }
                           : {
                                animation:
                                   "openMenuProfile .5s ease-in-out forwards",
                             }
                     }
                  >
                     <defs>
                        <style></style>
                     </defs>
                     <g id="Layer_2-2" data-name="Layer 2">
                        <circle
                           className="cls-2"
                           cx="125"
                           cy="127.55"
                           r="125"
                        />
                        <text
                           className="cls-1"
                           transform="translate(45.03 159.37)"
                        >
                           <tspan x="0" y="0">
                              MM
                           </tspan>
                        </text>
                     </g>
                  </svg>
                  {!closedMenu ? (
                     <h2 className="username">Mthobisi Magubane</h2>
                  ) : null}
                  {!closedMenu ? <h3 className="role">Admin</h3> : null}
                  <ul className="menu">
                     <li className="leads">
                        <Link to={`/${authUser.username}/leads`}>
                           <svg
                              viewBox="0 -64 640 640"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path d="M433.46 165.94l101.2-111.87C554.61 34.12 540.48 0 512.26 0H31.74C3.52 0-10.61 34.12 9.34 54.07L192 256v155.92c0 12.59 5.93 24.44 16 32l79.99 60c20.86 15.64 48.47 6.97 59.22-13.57C310.8 455.38 288 406.35 288 352c0-89.79 62.05-165.17 145.46-186.06zM480 192c-88.37 0-160 71.63-160 160s71.63 160 160 160 160-71.63 160-160-71.63-160-160-160zm16 239.88V448c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V256c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.04 44.44-42.67 45.07z" />
                           </svg>
                           {!closedMenu ? <h2>Leads</h2> : null}
                        </Link>
                     </li>
                     {/* <li className="chat">
                        <Link to={`/${authUser.username}/chat`}>
                           <svg
                              id="Layer_2"
                              data-name="Layer 2"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 494.55 494.55"
                              style={
                                 closedMenu
                                    ? {
                                         animation:
                                            "closeMenuItems .5s ease-in-out forwards",
                                      }
                                    : {
                                         animation:
                                            "openMenuItems .5s ease-in-out forwards",
                                      }
                              }
                           >
                              <g id="Layer_1-2" data-name="Layer 1">
                                 <path
                                    className="cls-1"
                                    d="m247.28,469.55c122.76,0,222.28-99.52,222.28-222.28S370.04,25,247.28,25,25,124.52,25,247.28c0,35.56,8.35,69.16,23.19,98.97,3.94,7.92,5.26,16.97,2.97,25.52l-13.24,49.48c-5.75,21.48,13.9,41.13,35.38,35.38l49.48-13.24c8.55-2.29,17.6-.97,25.52,2.97,29.8,14.85,63.41,23.19,98.97,23.19Z"
                                 />
                              </g>
                           </svg>
                           {!closedMenu ? <h2>Chat</h2> : null}
                        </Link>
                     </li> */}
                  </ul>
                  <button
                     onClick={handleLogout}
                     style={
                        closedMenu
                           ? {
                                animation:
                                   "closeMenuBtn .5s ease-in-out forwards",
                             }
                           : {
                                animation:
                                   "openMenuBtn .5s ease-in-out forwards",
                             }
                     }
                  >
                     <svg
                        id="Layer_2"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 494.03 494.03"
                        style={
                           closedMenu
                              ? {
                                   animation:
                                      "closeMenuBtnSvg .5s ease-in-out forwards",
                                }
                              : {
                                   animation:
                                      "openMenuBtnSvg .5s ease-in-out forwards",
                                }
                        }
                     >
                        <g id="Layer_1-2" data-name="Layer 1">
                           <g
                              id="Interface_Log_Out"
                              data-name="Interface / Log_Out"
                           >
                              <path
                                 id="Vector"
                                 className="cls-1"
                                 d="m247.01,327.14l80.13-80.13m0,0l-80.13-80.13m80.13,80.13H33.33m133.55-126.91v-1.29c0-29.92,0-44.89,5.82-56.32,5.12-10.05,13.29-18.22,23.34-23.34,11.43-5.82,26.4-5.82,56.32-5.82h122.87c29.92,0,44.86,0,56.28,5.82,10.05,5.12,18.25,13.29,23.37,23.34,5.82,11.42,5.82,26.37,5.82,56.23v256.6c0,29.86,0,44.79-5.82,56.21-5.12,10.05-13.31,18.23-23.37,23.35-11.42,5.82-26.35,5.82-56.21,5.82h-123.03c-29.86,0-44.81,0-56.23-5.82-10.05-5.12-18.22-13.31-23.34-23.36-5.82-11.43-5.82-26.38-5.82-56.29v-1.34"
                              />
                           </g>
                        </g>
                     </svg>
                     {!closedMenu ? <h3>Log out</h3> : null}
                  </button>
               </div>
               <main>{<Outlet />}</main>
            </div>
         ) : (
            <div className="container1">
               <div className="AdminHeaderMobile">
                  <div
                     className="sideBar"
                     style={
                        closedMenu
                           ? {
                                display: "flex",
                                animation:
                                   "openMenuSideBar .5s ease-in-out forwards",
                             }
                           : {
                                display: "none",
                             }
                     }
                  >
                     <div
                        className="close1"
                        onClick={() => setClosedMenu(!closedMenu)}
                     >
                        <svg
                           id="Layer_2"
                           data-name="Layer 2"
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 492.6 492.6"
                        >
                           <line
                              className="cls-1"
                              x1="25"
                              y1="25"
                              x2="467.6"
                              y2="467.6"
                           />
                           <line
                              className="cls-1"
                              x1="467.6"
                              y1="25"
                              x2="25"
                              y2="467.6"
                           />
                        </svg>
                     </div>
                     <div className="profile1">
                        <svg
                           id="Layer_2"
                           data-name="Layer 2"
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 491.7 491.7"
                        >
                           <g id="Layer_1-2" data-name="Layer 1">
                              <g id="Layer_2-2" data-name="Layer 2-2">
                                 <circle
                                    className="cls-3"
                                    cx="245.85"
                                    cy="245.85"
                                    r="245.85"
                                 />
                                 <g className="cls-2">
                                    <text
                                       className="cls-1"
                                       transform="translate(88.57 308.43)"
                                    >
                                       <tspan x="0" y="0">
                                          MM
                                       </tspan>
                                    </text>
                                 </g>
                              </g>
                           </g>
                        </svg>
                     </div>
                     <h2 className="username">Mthobisi Magubane</h2>
                     <h3 className="role">Admin</h3>
                     <ul className="menu">
                        <li className="leads">
                           <Link to={`/${authUser.username}/leads`}>
                              <svg
                                 viewBox="0 -64 640 640"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path d="M433.46 165.94l101.2-111.87C554.61 34.12 540.48 0 512.26 0H31.74C3.52 0-10.61 34.12 9.34 54.07L192 256v155.92c0 12.59 5.93 24.44 16 32l79.99 60c20.86 15.64 48.47 6.97 59.22-13.57C310.8 455.38 288 406.35 288 352c0-89.79 62.05-165.17 145.46-186.06zM480 192c-88.37 0-160 71.63-160 160s71.63 160 160 160 160-71.63 160-160-71.63-160-160-160zm16 239.88V448c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V256c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.04 44.44-42.67 45.07z" />
                              </svg>
                              <h2>Leads</h2>
                           </Link>
                        </li>
                     </ul>
                     <button>
                        <svg
                           id="Layer_2"
                           data-name="Layer 2"
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 494.03 494.03"
                        >
                           <g id="Layer_1-2" data-name="Layer 1">
                              <g
                                 id="Interface_Log_Out"
                                 data-name="Interface / Log_Out"
                              >
                                 <path
                                    id="Vector"
                                    className="cls-1"
                                    d="m247.01,327.14l80.13-80.13m0,0l-80.13-80.13m80.13,80.13H33.33m133.55-126.91v-1.29c0-29.92,0-44.89,5.82-56.32,5.12-10.05,13.29-18.22,23.34-23.34,11.43-5.82,26.4-5.82,56.32-5.82h122.87c29.92,0,44.86,0,56.28,5.82,10.05,5.12,18.25,13.29,23.37,23.34,5.82,11.42,5.82,26.37,5.82,56.23v256.6c0,29.86,0,44.79-5.82,56.21-5.12,10.05-13.31,18.23-23.37,23.35-11.42,5.82-26.35,5.82-56.21,5.82h-123.03c-29.86,0-44.81,0-56.23-5.82-10.05-5.12-18.22-13.31-23.34-23.36-5.82-11.43-5.82-26.38-5.82-56.29v-1.34"
                                 />
                              </g>
                           </g>
                        </svg>
                        <h3>Log out</h3>
                     </button>
                  </div>
                  <svg
                     className="sideMenu"
                     id="Layer_2"
                     data-name="Layer 2"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 497.09 374.11"
                     onClick={() => setClosedMenu(!closedMenu)}
                  >
                     <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Menu_Menu_Alt_03" data-name="Menu / Menu_Alt_03">
                           <path
                              id="Vector"
                              className="cls-1"
                              d="m33.33,340.78h245.96M33.33,187.06h430.43M33.33,33.33h245.96"
                           />
                        </g>
                     </g>
                  </svg>
                  <svg
                     id="Layer_2"
                     className="logo"
                     data-name="Layer 2"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 473.87 193.53"
                  >
                     <g id="Layer_2-2" data-name="Layer 2">
                        <g id="Logo">
                           <g>
                              <path
                                 className="cls-3"
                                 d="m26.84,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                              />
                              <path
                                 className="cls-3"
                                 d="m93.14,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                              />
                              <path
                                 className="cls-3"
                                 d="m189.71,106.54l1.48-7.03h21.45l-1.44,7.03h-21.49Z"
                              />
                              <path
                                 className="cls-3"
                                 d="m216.02,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                              />
                              <path
                                 className="cls-3"
                                 d="m311.78,112l6.84.7c-.98,3.39-3.25,6.62-6.78,9.68-3.54,3.06-7.75,4.59-12.65,4.59-3.06,0-5.86-.71-8.41-2.12-2.55-1.41-4.5-3.47-5.83-6.16-1.33-2.69-2-5.76-2-9.21,0-4.51,1.04-8.88,3.13-13.12,2.09-4.24,4.79-7.38,8.1-9.44,3.32-2.06,6.9-3.09,10.77-3.09,4.92,0,8.85,1.53,11.8,4.59,2.94,3.06,4.41,7.23,4.41,12.51,0,2.02-.18,4.09-.54,6.22h-30.39c-.1.8-.16,1.53-.16,2.18,0,3.86.89,6.81,2.66,8.84,1.77,2.03,3.94,3.05,6.51,3.05,2.41,0,4.78-.79,7.11-2.37,2.33-1.58,4.15-3.86,5.44-6.84Zm-20.44-10.22h23.16c.03-.73.04-1.24.04-1.55,0-3.52-.88-6.22-2.64-8.1-1.76-1.88-4.03-2.82-6.8-2.82-3.01,0-5.75,1.04-8.22,3.11-2.47,2.07-4.32,5.19-5.54,9.37Z"
                              />
                              <path
                                 className="cls-3"
                                 d="m353.55,120.05c-4.02,4.61-8.21,6.92-12.59,6.92-3.91,0-7.17-1.44-9.77-4.33-2.6-2.89-3.91-7.08-3.91-12.57,0-5.03,1.03-9.62,3.09-13.78,2.06-4.16,4.64-7.27,7.73-9.35,3.1-2.07,6.2-3.11,9.31-3.11,5.13,0,9,2.47,11.62,7.42l4.66-22.19h7l-11.89,56.97h-6.49l1.24-5.98Zm-19.28-11.31c0,2.88.28,5.14.85,6.8.57,1.66,1.54,3.04,2.91,4.14,1.37,1.1,3.02,1.65,4.94,1.65,3.19,0,6.08-1.66,8.67-4.97,3.47-4.4,5.21-9.85,5.21-16.32,0-3.26-.85-5.82-2.56-7.66-1.71-1.84-3.86-2.76-6.45-2.76-1.68,0-3.22.38-4.61,1.13-1.39.75-2.76,2.03-4.12,3.83-1.36,1.8-2.51,4.09-3.44,6.86-.93,2.77-1.4,5.21-1.4,7.31Z"
                              />
                              <path
                                 className="cls-3"
                                 d="m369.72,126.03l8.63-41.27h7.03l-8.63,41.27h-7.03Zm10.26-49.01l1.67-7.97h7l-1.67,7.97h-7Z"
                              />
                              <path
                                 className="cls-3"
                                 d="m415.5,120.9c-2.44,2.1-4.78,3.63-7.03,4.61-2.25.97-4.66,1.46-7.23,1.46-3.81,0-6.88-1.12-9.21-3.36-2.33-2.24-3.5-5.11-3.5-8.61,0-2.31.52-4.35,1.57-6.12,1.05-1.77,2.44-3.2,4.18-4.27,1.74-1.07,3.86-1.85,6.37-2.31,1.58-.31,4.58-.56,9-.74,4.42-.18,7.58-.65,9.5-1.4.54-1.92.82-3.51.82-4.78,0-1.63-.6-2.91-1.79-3.85-1.63-1.3-4.02-1.94-7.15-1.94-2.95,0-5.37.65-7.25,1.96-1.88,1.31-3.25,3.17-4.1,5.58l-7.11-.62c1.45-4.09,3.75-7.23,6.9-9.4s7.12-3.26,11.91-3.26c5.1,0,9.15,1.22,12.13,3.65,2.28,1.81,3.42,4.17,3.42,7.07,0,2.2-.32,4.75-.97,7.66l-2.29,10.26c-.73,3.26-1.09,5.92-1.09,7.97,0,1.3.28,3.16.85,5.6h-7.11c-.39-1.35-.66-3.06-.82-5.13Zm2.6-15.78c-.98.39-2.04.69-3.17.89-1.13.21-3.01.43-5.66.66-4.09.36-6.98.82-8.67,1.38-1.68.56-2.95,1.44-3.81,2.66-.85,1.22-1.28,2.56-1.28,4.04,0,1.97.68,3.59,2.04,4.86,1.36,1.27,3.3,1.9,5.81,1.9,2.33,0,4.57-.61,6.72-1.85,2.15-1.23,3.85-2.95,5.09-5.15,1.24-2.2,2.22-5.34,2.91-9.4Z"
                              />
                           </g>
                           <path
                              className="cls-2"
                              d="m124.71,151.77c12.46-11.76,31.16-14.53,45.17-17.09,6.36-11.9,8.38-26.43-4.65-20.68-2.21.96-3.3-2.56-2.17-3.54,6.04-5.42,15.77-13.18,19.4-20.95,7.73-16.41-14.96-8.1-25.06,15.38-.89,2.13-5.05,1.17-3.89-1.16,14.57-30.13,41.46-31.36,35.37-14.39-2.68,7.31-11.66,15.72-16.97,20.65,9.01,1.74,9.94,11.11,5.7,22.9,11.65-3.23,27.78-15.04,32.68-24.21.71-1.44,3.17-.41,2.21,1.09-9.2,14.37-22.14,22.28-36.72,27.46-7.36,14.02-20.18,30.08-35.32,37.49-18.84,9.29-33.54-6.32-15.75-22.96Zm15.74,16.06c8.16-4.84,19.05-13.46,28.39-29.89-62.73,9.98-52.36,44.35-28.39,29.89Z"
                           />
                        </g>
                     </g>
                  </svg>
                  <svg
                     id="Layer_2"
                     data-name="Layer 2"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 491.7 491.7"
                     className="profile"
                  >
                     <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Layer_2-2" data-name="Layer 2-2">
                           <circle
                              className="cls-3"
                              cx="245.85"
                              cy="245.85"
                              r="245.85"
                           />
                           <g className="cls-2">
                              <text
                                 className="cls-1"
                                 transform="translate(88.57 308.43)"
                              >
                                 <tspan x="0" y="0">
                                    MM
                                 </tspan>
                              </text>
                           </g>
                        </g>
                     </g>
                  </svg>
               </div>
               <main>{<Outlet />}</main>
            </div>
         )}
      </div>
   );
};

export default AdminHeader;
