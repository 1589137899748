import "./App.css";
import {
   BrowserRouter,
   Routes,
   Route,
   Outlet,
   Navigate,
} from "react-router-dom";
import { useAuthContext } from "./Context/AuthContext.jsx";

import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Chat from "./pages/Chat/Chat";
import Leads from "./pages/Leads/Leads";
import Dashboard from "./pages/Dashboard/Dashboard";

import ProtectedRoutes from "./middleware/ProtectedRoutes.jsx";
import LeadsNoWebsite from "./pages/LeadsNoWebsite/LeadsNoWebsite.jsx";

function App() {
   const { authUser } = useAuthContext();
   return (
      <div className="App">
         <BrowserRouter>
            <Routes>
               <Route element={<Outlet />}>
                  <Route
                     path="/"
                     element={
                        authUser === null ? (
                           <Login />
                        ) : (
                           <Navigate to={`/${authUser.username}/dashboard`} />
                        )
                     }
                  />
                  <Route path="/register" element={<Register />} />
                  <Route path="/:username" element={<ProtectedRoutes />}>
                     <Route path="dashboard" element={<Dashboard />} />
                     <Route path="leads" element={<Leads />} />
                     <Route
                        path="leads/no-website"
                        element={<LeadsNoWebsite />}
                     />
                     <Route
                        path="chat"
                        element={authUser ? <Chat /> : <Navigate to="/" />}
                     />
                  </Route>
               </Route>
            </Routes>
         </BrowserRouter>
      </div>
   );
}

export default App;
