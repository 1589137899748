import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./Register.css";

const Register = () => {
   const [username, setUsername] = useState("");
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [password, setPassword] = useState("");

   const [error, setError] = useState("");

   const navigate = useNavigate();

   const handleValidation = () => {
      if (username === "") {
         setError("Username is a required field");
         return false;
      } else if (firstName === "") {
         setError("First Name is a required field");
         return false;
      } else if (lastName === "") {
         setError("Last Name is a required field");
         return false;
      } else if (email === "") {
         setError("Email is a required field");
         return false;
      } else if (!email.includes("@")) {
         setError("Invalid email");
         return false;
      } else if (password === "") {
         setError("Password is a required field");
         return false;
      } else if (password !== confirmPassword) {
         setError("Passwords do not match");
         return false;
      }
      return true;
   };

   const handleSubmit = async (e) => {
      try {
         e.preventDefault();
         if (handleValidation()) {
            const response = await axios.post("/api/users/register-a-user", {
               username: username.replace(/\s+/g, "").toLowerCase(),
               fullName: `${firstName} ${lastName}`,
               email,
               password,
            });
            if (response.data.hasOwnProperty("_id")) {
               localStorage.setItem(
                  "MMZ-Media-User",
                  JSON.stringify(response.data)
               );
               navigate(`/${response.data.username}/dashboard`);
            }
            if (response.data.error) {
               setError(response.data.error);
            }
         }
      } catch (error) {
         alert(error);
      }
   };

   return (
      <div className="Register">
         <h1>Register a user</h1>
         <form onSubmit={(e) => handleSubmit(e)} noValidate>
            {error ? <p className="error">{error}</p> : null}
            <label>
               <h3>Username</h3>
            </label>
            <input
               type="text"
               placeholder="JohnDoe"
               onChange={(e) => {
                  setError("");
                  setUsername(e.target.value);
               }}
            />
            <label>
               <h3>Full name</h3>
            </label>
            <div className="names">
               <input
                  type="text"
                  placeholder="First name"
                  onChange={(e) => {
                     setError("");
                     setFirstName(e.target.value);
                  }}
               />
               <input
                  type="text"
                  placeholder="Last name"
                  onChange={(e) => {
                     setError("");
                     setLastName(e.target.value);
                  }}
               />
            </div>
            <label>
               <h3>Email</h3>
            </label>
            <input
               type="text"
               placeholder="johndoe@example.com"
               onChange={(e) => {
                  setError("");
                  setEmail(e.target.value);
               }}
            />
            <label>
               <h3>Password</h3>
            </label>
            <input
               type="password"
               placeholder="*********"
               onChange={(e) => {
                  setError("");
                  setPassword(e.target.value);
               }}
            />
            <label>
               <h3>Confirm password</h3>
            </label>
            <input
               type="password"
               placeholder="*********"
               onChange={(e) => {
                  setError("");
                  setConfirmPassword(e.target.value);
               }}
            />
            <button type="submit">Register</button>
         </form>
      </div>
   );
};

export default Register;
