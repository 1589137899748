import React from "react";
import axios from "axios";

import "./Lead.css";

const Lead = ({ lead }) => {
   const changeStatus = async (e) => {
      if (e.target.value !== null) {
         await axios.post("/api/lead/updateStatus", {
            businessName: lead.businessName,
            status: e.target.value,
         });
      }
   };

   return (
      <div className="lead">
         <h3>{lead.businessName}</h3>
         <a href={`https://${lead.website}`} target="_blank" rel="noreferrer">
            <p>{lead.website}</p>
         </a>
         <p>{lead.address}</p>
         <a
            href={`https://wa.me/${lead.phoneNumber}`}
            target="_blank"
            rel="noreferrer"
         >
            <p>{lead.phoneNumber}</p>
         </a>
         <p>Status: {lead.status}</p>
         {/* <div className="assignContainer">
            <p>Assign Contact</p>
            <select name="fruits">
               <option value="">No one</option>
               <option value="Mthobisi Magubane">Mthobisi Magubane</option>
               <option value="banana">Segopotso Molefe</option>
            </select>
         </div> */}
         <select
            name="status"
            onChange={(e) => changeStatus(e)}
            className="statusDropDown"
         >
            <option value={null}>Status</option>
            <option value="texted">Texted</option>
            <option value="answered">Answered</option>
            <option value="send link">Send Link</option>
            <option value="sent link">Sent Link</option>
         </select>
      </div>
   );
};

export default Lead;
