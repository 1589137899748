import React from "react";
import "./Chat.css";
import Conversations from "../../components/Conversations/Conversations";

const Message = () => {
   return (
      <div className="Chat">
         <h1>Chat</h1>
         <div className="container">
            <Conversations/>
            <div className="right">
               <div className="head">
                  <div className="profile">
                     <svg
                        id="Layer_2"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 491.7 491.7"
                     >
                        <g id="Layer_1-2" data-name="Layer 1">
                           <g id="Layer_2-2" data-name="Layer 2-2">
                              <circle
                                 className="cls-3"
                                 cx="245.85"
                                 cy="245.85"
                                 r="245.85"
                              />
                              <g className="cls-2">
                                 <text
                                    className="cls-1"
                                    transform="translate(88.57 308.43)"
                                 >
                                    <tspan x="0" y="0">
                                       MM
                                    </tspan>
                                 </text>
                              </g>
                           </g>
                        </g>
                     </svg>
                  </div>
                  <div className="text">
                     <h2>Nancy Fernandez</h2>
                     <p>Online</p>
                  </div>
                  {/* <div className="options">
                     <svg
                        id="Layer_2"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 487.14 495.36"
                        className="assign"
                     >
                        <g id="Layer_1-2" data-name="Layer 1">
                           <g>
                              <path
                                 className="cls-1"
                                 d="m285.15,423.97H38.3c-7.82,0-12.4-6.04-11.72-11.56,9.77-78.48,65.34-142.81,139.15-165.32,4.99-1.52,8.63-5.84,9.28-11.02.65-5.18-1.8-10.26-6.26-12.98-30.59-18.62-50.97-52.24-50.97-90.61,0-58.54,47.45-105.99,105.99-105.99s105.99,47.45,105.99,105.99c0,38.37-20.38,71.99-50.97,90.61-4.46,2.72-6.91,7.8-6.25,12.98.65,5.18,4.28,9.49,9.28,11.02,26.66,8.13,50.95,21.72,71.55,39.47,9.45-4.63,19.4-7.43,29.85-8.4-20.02-20.06-43.79-36.37-70.18-47.78,26.54-24.22,43.22-59.1,43.22-97.89C356.28,59.32,296.96,0,223.79,0S91.3,59.32,91.3,132.49c0,38.79,16.68,73.67,43.22,97.89C62.75,261.39,10.31,328.65.29,409.13c-2.93,23.53,16.56,41.33,38.01,41.33h258.22c-4.84-8.22-8.63-17.05-11.38-26.5Z"
                              />
                              <path
                                 className="cls-1"
                                 d="m13.44,410.77l-13.15-1.64,13.15,1.64Z"
                              />
                           </g>
                           <path
                              className="cls-1"
                              d="m487.14,394.64c0,5.51-2.26,10.54-5.9,14.18-3.64,3.64-8.65,5.9-14.18,5.9h-60.59v60.59c0,11.04-9.02,20.06-20.06,20.06-5.52,0-10.54-2.26-14.16-5.9-3.64-3.63-5.9-8.64-5.9-14.16v-60.59h-60.6c-11.04,0-20.06-9.04-20.06-20.07,0-5.51,2.26-10.53,5.9-14.16,3.64-3.64,8.65-5.9,14.16-5.9h60.6v-60.59c0-11.04,9.02-20.06,20.06-20.06,5.52,0,10.54,2.26,14.16,5.9,3.64,3.64,5.9,8.65,5.9,14.16v60.59h60.59c11.04,0,20.07,9.04,20.07,20.06Z"
                           />
                        </g>
                     </svg>
                     <svg
                        id="Layer_2"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                        className="info"
                     >
                        <g id="Layer_1-2" data-name="Layer 1">
                           <circle
                              className="cls-1"
                              cx="250"
                              cy="250"
                              r="229.94"
                           />
                           <text
                              className="cls-2"
                              transform="translate(218.01 353.1)"
                           >
                              <tspan x="0" y="0">
                                 i
                              </tspan>
                           </text>
                        </g>
                     </svg>
                     <svg
                        id="Layer_2"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 110 510"
                        className="more"
                     >
                        <g id="Layer_1-2" data-name="Layer 1">
                           <circle
                              className="cls-1"
                              cx="50.7"
                              cy="250"
                              r="50.7"
                           />
                           <circle
                              className="cls-1"
                              cx="50.7"
                              cy="50.7"
                              r="50.7"
                           />
                           <circle
                              className="cls-1"
                              cx="50.7"
                              cy="449.3"
                              r="50.7"
                           />
                        </g>
                     </svg>
                  </div> */}
               </div>
               <div className="messageContainer">
                  <p className="message">
                     Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                     Officiis ducimus necessitatibus explicabo assumenda ipsam
                     autem? Quisquam et praesentium veritatis totam tenetur,
                     amet illo reiciendis. Quae pariatur quibusdam eos incidunt
                     similique voluptate et vitae fuga! Ea dignissimos natus
                     repudiandae nam excepturi eaque et a eveniet impedit.
                  </p>
                  <div className="info">
                     <div className="profile">
                        <svg
                           id="Layer_2"
                           data-name="Layer 2"
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 491.7 491.7"
                        >
                           <g id="Layer_1-2" data-name="Layer 1">
                              <g id="Layer_2-2" data-name="Layer 2-2">
                                 <circle
                                    className="cls-3"
                                    cx="245.85"
                                    cy="245.85"
                                    r="245.85"
                                 />
                                 <g className="cls-2">
                                    <text
                                       className="cls-1"
                                       transform="translate(88.57 308.43)"
                                    >
                                       <tspan x="0" y="0">
                                          MM
                                       </tspan>
                                    </text>
                                 </g>
                              </g>
                           </g>
                        </svg>
                        <p>12:45</p>
                     </div>
                  </div>
               </div>
               <div className="messageContainer">
                  <p
                     className="message"
                     style={{
                        borderRadius: "15px 15px 0px 15px",
                        alignSelf: "flex-end",
                     }}
                  >
                     Okay
                  </p>
                  <div className="info">
                     <div
                        className="profile"
                        style={{ justifyContent: "flex-end" }}
                     >
                        <svg
                           id="Layer_2"
                           data-name="Layer 2"
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 491.7 491.7"
                        >
                           <g id="Layer_1-2" data-name="Layer 1">
                              <g id="Layer_2-2" data-name="Layer 2-2">
                                 <circle
                                    className="cls-3"
                                    cx="245.85"
                                    cy="245.85"
                                    r="245.85"
                                 />
                                 <g className="cls-2">
                                    <text
                                       className="cls-1"
                                       transform="translate(88.57 308.43)"
                                    >
                                       <tspan x="0" y="0">
                                          MM
                                       </tspan>
                                    </text>
                                 </g>
                              </g>
                           </g>
                        </svg>
                        <p>12:48</p>
                     </div>
                  </div>
               </div>
               <div className="send">
                  <input
                     type="text"
                     name=""
                     id=""
                     placeholder="Type a message"
                  />
                  <svg
                     id="Layer_2"
                     data-name="Layer 2"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 500.53 492.53"
                  >
                     <g id="Layer_1-2" data-name="Layer 1">
                        <circle
                           className="cls-2"
                           cx="246.27"
                           cy="246.27"
                           r="245.77"
                        />
                        <g id="Layer_1-2" data-name="Layer 1-2">
                           <path
                              className="cls-1"
                              d="m246.27,78.38c4.95,0,9.69,1.97,13.19,5.46l130.58,130.58c7.29,7.29,7.29,19.1,0,26.38-7.29,7.29-19.1,7.29-26.38,0l-98.74-98.74v253.44c0,10.3-8.35,18.65-18.65,18.65s-18.65-8.35-18.65-18.65v-253.44l-98.74,98.74c-7.29,7.29-19.09,7.29-26.38,0-7.29-7.29-7.29-19.1,0-26.38L233.08,83.84c3.5-3.5,8.24-5.46,13.19-5.46h0Z"
                           />
                        </g>
                     </g>
                  </svg>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Message;
