import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../Context/AuthContext.jsx";

import Lead from "../../components/Lead/Lead";

// import SendSite from "../../components/SendSite/SendSite";

import "./Leads.css";

const Leads = () => {
   const [leads, setLeads] = useState([]);

   const navigate = useNavigate();

   const { authUser, setAuthUser } = useAuthContext();

   useEffect(() => {
      axios.get("/api/lead/getAll").then((res) => {
         setLeads(res.data);
      });
   }, []);
   return (
      <div className="Leads">
         <h1>Leads</h1>
         <div className="type">
            <div
               className="typeName"
               style={{ backgroundColor: "#ff110050" }}
               onClick={() =>
                  navigate(`/${authUser.username}/leads/no-website`)
               }
            >
               <h1>
                  <b>
                     {
                        leads.filter(
                           (lead) =>
                                 lead.website.includes("wordpress") ||
                                 lead.website.includes("wixsite") ||
                                 lead.website.includes("facebook") ||
                                 lead.website.includes("canva") ||
                                 lead.website.includes("business.site")
                        ).length
                     }
                  </b>
               </h1>
               <h2>No website</h2>
            </div>
            <div
               className="typeName"
               style={{ backgroundColor: "#00FF0070" }}
               onClick={() =>
                  navigate(`/${authUser.username}/leads/google-website`)
               }
            >
               <h1>
                  <b>
                     {
                        leads.filter((lead) =>
                           lead.website.includes("business.site")
                        ).length
                     }
                  </b>
               </h1>
               <h2>Google websites</h2>
            </div>
            <div
               className="typeName"
               style={{ backgroundColor: "#e6b40090" }}
               onClick={() => navigate(`/${authUser.username}/leads/websites`)}
            >
               <h1>
                  <b>
                     {
                        leads.filter(
                           (lead) =>
                              !lead.website.includes("wordpress") ||
                              !lead.website.includes("wixsite") ||
                              !lead.website.includes("facebook") ||
                              !lead.website.includes("canva") ||
                              !lead.website.includes("business.site")
                        ).length
                     }
                  </b>
               </h1>
               <h2>Websites</h2>
            </div>
         </div>
      </div>
   );
};

export default Leads;
