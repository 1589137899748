import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../Context/AuthContext.jsx";

import "./Login.css";

const Login = () => {
   const [inputs, setInputs] = useState({ username: "", password: "" });
   const [error, setError] = useState({
      isError: false,
   });

   const navigate = useNavigate();
   const { setAuthUser } = useAuthContext();

   const handleValidation = () => {
      try {
         if (inputs.username === "" && inputs.password === "") {
            setError({
               ...error,
               message: "Please fill in the required fields",
               isError: true,
            });
            return false;
         } else if (inputs.username === "") {
            setError({
               ...error,
               message: "Please fill in username or email field",
               isError: true,
            });
            return false;
         } else if (inputs.password === "") {
            setError({
               ...error,
               message: "Please fill in password field",
               isError: true,
            });
            return false;
         }
         return true;
      } catch (e) {
         console.log(e);
      }
   };

   const handleFormSubmit = (e) => {
      e.preventDefault();
      try {
         if (handleValidation()) {
            axios
               .post("/api/auth/login", {
                  username: inputs.username,
                  password: inputs.password,
               })
               .then((res) => {
                  if (res.data.error) {
                     setError({ message: res.data.error, isError: true });
                  } else {
                     localStorage.setItem(
                        "MMZ-Media-User",
                        JSON.stringify(res.data)
                     );
                     setAuthUser(res.data);
                     navigate(`/${res.data.username}/dashboard`);
                  }
               });
         }
      } catch (error) {}
   };

   return (
      <div className="login">
         <div className="logo">
            <svg
               id="Layer_2"
               className="logo"
               data-name="Layer 2"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 473.87 193.53"
            >
               <g id="Layer_2-2" data-name="Layer 2">
                  <g id="Logo">
                     <g>
                        <path
                           className="cls-3"
                           d="m26.84,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                        />
                        <path
                           className="cls-3"
                           d="m93.14,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                        />
                        <path
                           className="cls-3"
                           d="m189.71,106.54l1.48-7.03h21.45l-1.44,7.03h-21.49Z"
                        />
                        <path
                           className="cls-3"
                           d="m216.02,126.03l11.89-56.97h9.37l5.75,36.92c.75,4.85,1.24,9.5,1.48,13.95,1.58-3.83,4.22-9.03,7.93-15.58l19.98-35.29h9.52l-11.89,56.97h-7.5l5.95-27.71c1.37-6.4,3.34-13.58,5.91-21.53-1.63,3.71-3.65,7.69-6.06,11.97l-21.22,37.27h-7.35l-5.71-36.53c-.52-3.34-.89-7.24-1.13-11.7-.65,5-1.28,8.96-1.9,11.89l-7.58,36.34h-7.42Z"
                        />
                        <path
                           className="cls-3"
                           d="m311.78,112l6.84.7c-.98,3.39-3.25,6.62-6.78,9.68-3.54,3.06-7.75,4.59-12.65,4.59-3.06,0-5.86-.71-8.41-2.12-2.55-1.41-4.5-3.47-5.83-6.16-1.33-2.69-2-5.76-2-9.21,0-4.51,1.04-8.88,3.13-13.12,2.09-4.24,4.79-7.38,8.1-9.44,3.32-2.06,6.9-3.09,10.77-3.09,4.92,0,8.85,1.53,11.8,4.59,2.94,3.06,4.41,7.23,4.41,12.51,0,2.02-.18,4.09-.54,6.22h-30.39c-.1.8-.16,1.53-.16,2.18,0,3.86.89,6.81,2.66,8.84,1.77,2.03,3.94,3.05,6.51,3.05,2.41,0,4.78-.79,7.11-2.37,2.33-1.58,4.15-3.86,5.44-6.84Zm-20.44-10.22h23.16c.03-.73.04-1.24.04-1.55,0-3.52-.88-6.22-2.64-8.1-1.76-1.88-4.03-2.82-6.8-2.82-3.01,0-5.75,1.04-8.22,3.11-2.47,2.07-4.32,5.19-5.54,9.37Z"
                        />
                        <path
                           className="cls-3"
                           d="m353.55,120.05c-4.02,4.61-8.21,6.92-12.59,6.92-3.91,0-7.17-1.44-9.77-4.33-2.6-2.89-3.91-7.08-3.91-12.57,0-5.03,1.03-9.62,3.09-13.78,2.06-4.16,4.64-7.27,7.73-9.35,3.1-2.07,6.2-3.11,9.31-3.11,5.13,0,9,2.47,11.62,7.42l4.66-22.19h7l-11.89,56.97h-6.49l1.24-5.98Zm-19.28-11.31c0,2.88.28,5.14.85,6.8.57,1.66,1.54,3.04,2.91,4.14,1.37,1.1,3.02,1.65,4.94,1.65,3.19,0,6.08-1.66,8.67-4.97,3.47-4.4,5.21-9.85,5.21-16.32,0-3.26-.85-5.82-2.56-7.66-1.71-1.84-3.86-2.76-6.45-2.76-1.68,0-3.22.38-4.61,1.13-1.39.75-2.76,2.03-4.12,3.83-1.36,1.8-2.51,4.09-3.44,6.86-.93,2.77-1.4,5.21-1.4,7.31Z"
                        />
                        <path
                           className="cls-3"
                           d="m369.72,126.03l8.63-41.27h7.03l-8.63,41.27h-7.03Zm10.26-49.01l1.67-7.97h7l-1.67,7.97h-7Z"
                        />
                        <path
                           className="cls-3"
                           d="m415.5,120.9c-2.44,2.1-4.78,3.63-7.03,4.61-2.25.97-4.66,1.46-7.23,1.46-3.81,0-6.88-1.12-9.21-3.36-2.33-2.24-3.5-5.11-3.5-8.61,0-2.31.52-4.35,1.57-6.12,1.05-1.77,2.44-3.2,4.18-4.27,1.74-1.07,3.86-1.85,6.37-2.31,1.58-.31,4.58-.56,9-.74,4.42-.18,7.58-.65,9.5-1.4.54-1.92.82-3.51.82-4.78,0-1.63-.6-2.91-1.79-3.85-1.63-1.3-4.02-1.94-7.15-1.94-2.95,0-5.37.65-7.25,1.96-1.88,1.31-3.25,3.17-4.1,5.58l-7.11-.62c1.45-4.09,3.75-7.23,6.9-9.4s7.12-3.26,11.91-3.26c5.1,0,9.15,1.22,12.13,3.65,2.28,1.81,3.42,4.17,3.42,7.07,0,2.2-.32,4.75-.97,7.66l-2.29,10.26c-.73,3.26-1.09,5.92-1.09,7.97,0,1.3.28,3.16.85,5.6h-7.11c-.39-1.35-.66-3.06-.82-5.13Zm2.6-15.78c-.98.39-2.04.69-3.17.89-1.13.21-3.01.43-5.66.66-4.09.36-6.98.82-8.67,1.38-1.68.56-2.95,1.44-3.81,2.66-.85,1.22-1.28,2.56-1.28,4.04,0,1.97.68,3.59,2.04,4.86,1.36,1.27,3.3,1.9,5.81,1.9,2.33,0,4.57-.61,6.72-1.85,2.15-1.23,3.85-2.95,5.09-5.15,1.24-2.2,2.22-5.34,2.91-9.4Z"
                        />
                     </g>
                     <path
                        className="cls-2"
                        d="m124.71,151.77c12.46-11.76,31.16-14.53,45.17-17.09,6.36-11.9,8.38-26.43-4.65-20.68-2.21.96-3.3-2.56-2.17-3.54,6.04-5.42,15.77-13.18,19.4-20.95,7.73-16.41-14.96-8.1-25.06,15.38-.89,2.13-5.05,1.17-3.89-1.16,14.57-30.13,41.46-31.36,35.37-14.39-2.68,7.31-11.66,15.72-16.97,20.65,9.01,1.74,9.94,11.11,5.7,22.9,11.65-3.23,27.78-15.04,32.68-24.21.71-1.44,3.17-.41,2.21,1.09-9.2,14.37-22.14,22.28-36.72,27.46-7.36,14.02-20.18,30.08-35.32,37.49-18.84,9.29-33.54-6.32-15.75-22.96Zm15.74,16.06c8.16-4.84,19.05-13.46,28.39-29.89-62.73,9.98-52.36,44.35-28.39,29.89Z"
                     />
                  </g>
               </g>
            </svg>
         </div>
         <form onSubmit={(e) => handleFormSubmit(e)}>
            <div className="inputs">
               <h1>Welcome back</h1>
               {error.isError ? <p className="error">{error.message}</p> : null}
               <label>
                  <h3>Username or Email</h3>
               </label>
               <input
                  type="text"
                  placeholder="JohnDoe or johndoe@example.com"
                  onChange={(e) => {
                     setError({
                        isError: false,
                     });
                     setInputs({ ...inputs, username: e.target.value });
                  }}
               />
               <label>
                  <h3>Password</h3>
               </label>
               <input
                  type="password"
                  placeholder="***********"
                  onChange={(e) =>
                     setInputs({ ...inputs, password: e.target.value })
                  }
               />
            </div>
            <button type="submit">Log in</button>
         </form>
      </div>
   );
};

export default Login;
