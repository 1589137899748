import React, { useEffect, useState } from "react";
import axios from "axios";
import { extractTime } from "../../Utils/GetTime.js";

import "./Conversation.css";

const Conversation = ({ messages, person, profilePicture }) => {
   const [name, setName] = useState("");
   const [time, setTime] = useState("");

   const getName = async () => {
      const res = await axios.post("/api/users/user", { userId: person });
      setName(res.data.fullName);
   };
   useEffect(() => {
      getName();
      setTime(extractTime(messages[messages.length - 1].createdAt));
   }, []);

   return (
      <div className="conversation">
         <div className="profile">
            <img src={profilePicture} alt="" />
         </div>
         <div className="text">
            <h3>{name}</h3>
            <p>{messages[messages.length - 1].message}</p>
         </div>
         <div className="info">
            <div className="time">{time}</div>
            <svg
               id="Layer_2"
               data-name="Layer 2"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 500 500"
            >
               <g id="Layer_1-2" data-name="Layer 1">
                  <circle className="cls-1" cx="250" cy="250" r="245.85" />
                  <text className="cls-2" transform="translate(169.91 353.1)">
                     <tspan x="0" y="0">
                        {messages.filter((item) => item.read === false).length}
                     </tspan>
                  </text>
               </g>
            </svg>
         </div>
      </div>
   );
};

export default Conversation;
