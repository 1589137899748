function extractTime(dateString) {
   const date = new Date(dateString);
   const now = new Date();

   if (date.toDateString() !== now.toDateString()) {
      const day = padZero(date.getDate());
      const monthAbbreviations = [
         "Jan",
         "Feb",
         "Mar",
         "Apr",
         "May",
         "Jun",
         "Jul",
         "Aug",
         "Sep",
         "Oct",
         "Nov",
         "Dec",
      ];
      const monthAbbreviation = monthAbbreviations[date.getMonth()];
      return `${day} ${monthAbbreviation}`;
   }

   const hours = padZero(date.getHours());
   const minutes = padZero(date.getMinutes());
   return `${hours}:${minutes}`;
}

// Helper function to pad single-digit numbers with a leading zero
function padZero(number) {
   return number.toString().padStart(2, "0");
}

export { extractTime };
