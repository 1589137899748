import { Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "../Context/AuthContext.jsx";
import AdminHeader from "../components/AdminHeader/AdminHeader.jsx";

const PrivateRoutes = () => {
   const { authUser } = useAuthContext();

   return authUser !== null ? (
      <AdminHeader>
         <Outlet />
      </AdminHeader>
   ) : (
      <Navigate to="/" />
   );
};

export default PrivateRoutes;
