import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../../Context/AuthContext.jsx";

import Conversation from "../Conversation/Conversation";
import "./Conversations.css";

const Conversations = () => {
   const [conversations, setConversations] = useState([]);

   const { authUser } = useAuthContext();

   const getUser = async () => {
      const res = await axios.get(
         `/api/messages/conversations/${authUser._id}`
      );
      setConversations(res.data);
      console.log(res.data);
   };
   useEffect(() => {
      getUser();
   }, []);
   return (
      <div className="conversations">
         <div className="search">
            <svg
               id="Layer_2"
               data-name="Layer 2"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 480.12 480.12"
            >
               <g id="Layer_1-2" data-name="Layer 1">
                  <path
                     className="cls-1"
                     d="m334.76,335.15l129.86,129.47m-74.82-262.07c0,103.42-83.83,187.25-187.25,187.25S15.5,305.97,15.5,202.75,99.33,15.5,202.75,15.5s187.25,83.83,187.25,187.25l-.2-.2Z"
                  />
               </g>
            </svg>
            <input type="text" name="" id="" placeholder="search" />
         </div>
         {conversations.map((conversation) => (
            <Conversation
               key={conversation._id}
               messages={conversation.messages}
               person={conversation.participants.filter(
                  (item) => item !== authUser._id
               )}
               profilePicture={conversation.profilePicture}
            />
         ))}
      </div>
   );
};

export default Conversations;
